<template>
  <div id="app">
    <StartWebChat
      v-show="showStartChat"
      :firstLoad="firstLoad"
      :imagePath="imagepath"
      :introText="introtext"
      @showChat="handleShowChat"
    />
    <div class="webchatMainWrapper" v-if="showBanner">
      <WebChatBanner
        :imagePath="imagepath"
        @close="handleClose"
        @shrink="handleShrink"
      />
      <WebChat
        :botAvatarInitials="botavatarinitials"
        :userAvatarInitials="useravatarinitials"
        :minimized="minimized"
        :stylenonce="stylenonce"
        :topic="topic"
        v-if="showWebchat"
      />
    </div>
  </div>
</template>

<script lang="ts">
import WebChat from "./components/WebChat.vue";
import StartWebChat from "./components/StartWebChat.vue";
import WebChatBanner from "./components/WebChatBanner.vue";
import { clearConversation, addWebChat } from "./util";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "App",

  props: {
    botavatarinitials: {
      type: String,
      required: true,
    },
    useravatarinitials: {
      type: String,
      required: true,
    },
    imagepath: {
      type: String,
      required: true,
    },
    introtext: {
      type: String,
      required: true,
    },
    stylenonce: {
      type: String,
      required: true,
    },
    topic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showWebchat: false,
      firstLoad: true,
      minimized: false,
    };
  },
  methods: {
    handleShowChat() {
      addWebChat(this.setWebChatVisible);
    },
    setWebChatVisible() {
      this.showWebchat = true;
      this.minimized = false;
    },
    handleClose() {
      this.showWebchat = false;
      clearConversation();
    },
    handleShrink() {
      this.minimized = true;
    },
  },
  computed: {
    showBanner(): boolean {
      return this.showWebchat && !this.minimized;
    },
    showStartChat(): boolean {
      return !this.showWebchat || this.minimized;
    },
  },
  components: {
    WebChat,
    StartWebChat,
    WebChatBanner,
  },
});
</script>
