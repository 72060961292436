import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const conversationId: OperationURLParameter = {
  parameterPath: "conversationId",
  mapper: {
    serializedName: "conversationId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const watermark: OperationQueryParameter = {
  parameterPath: ["options", "watermark"],
  mapper: {
    serializedName: "watermark",
    type: {
      name: "String",
    },
  },
};
