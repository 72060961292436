import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "webchat-container showWebchat",
  ref: "botwindow"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
      [_vShow, !_ctx.connectionError && !_ctx.minimized]
    ]),
    (_ctx.connectionError)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "There has been a connection error"))
      : _createCommentVNode("", true)
  ]))
}