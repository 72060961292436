<template>
  <div
    class="webchatPopup"
    :class="{ firstLoad: firstLoad, hideText: hideText }"
  >
    <div class="chatTextWrapper">
      <button
        class="chatText"
        @click="$emit('showChat')"
        v-show="isShow"
        :class="{ initialLoad: isFirstPage }"
      >
        {{ introText }}
      </button>

      <div class="chatTextCloseWrapper">
        <button
          aria-label="Hide chatbot text"
          class="chatTextClose"
          @click="handleHideChatBotText($event)"
          v-show="isShow"
          :class="{ initialLoad: isFirstPage }"
        >
          <img
            :src="imagePath + '/close-icon.svg'"
            alt=""
            role="presentation"
          />
        </button>
      </div>
    </div>

    <button
      aria-label="Open chatbot"
      class="chatbotOpen"
      :class="{ initialLoad: isFirstPage }"
      @click="$emit('showChat')"
    >
      <img
        id="chatbot-open"
        :src="imagePath + '/chatbot-bubble.svg'"
        alt=""
        role="presentation"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    introText: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    firstLoad: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hideText: false,
      isShow: true,
      isFirstPage: true,
    };
  },

  mounted() {
    this.init();
    this.getIsFirstPage();
  },
  methods: {
    init() {
      this.initDismissed();
      this.isShow = !this.isDismissed();
    },

    isDismissed() {
      return this.getIsDismissed() === "true";
    },

    initDismissed() {
      if (!this.getIsDismissed()) this.setIsDismissed("false");
    },

    getIsDismissed() {
      return sessionStorage.getItem("WebchatIsDismissed");
    },

    setIsDismissed(isTextDismissed: string) {
      sessionStorage.setItem("WebchatIsDismissed", isTextDismissed);
    },

    getIsFirstPage() {
      if (!sessionStorage.getItem("WebchatNoFirstVisit")) {
        this.isFirstPage = true;
        sessionStorage.setItem("WebchatNoFirstVisit", "true");
      } else {
        this.isFirstPage = false;
      }
    },

    handleHideChatBotText(event: Event): void {
      this.hideText = true;
      this.setIsDismissed("true");
      event.stopPropagation();
    },
  },
});
</script>
