const WEBCHAT_URL =
  "https://cdn.botframework.com/botframework-webchat/latest/webchat.js";

const SCRIPT_ID = "ms-chat";

export const addWebChat = (callback: () => void) => {
  const scriptElement = document.getElementById(SCRIPT_ID);

  if (scriptElement != null) {
    callback();
    return;
  }

  const myScript = document.createElement("script");
  myScript.setAttribute("src", WEBCHAT_URL);
  myScript.setAttribute("id", SCRIPT_ID);
  document.body.appendChild(myScript);

  myScript.addEventListener("load", callback, false);
};
