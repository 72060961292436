import { createApp, h } from "vue";
import { buildClient } from "./api/client";
import App from "./App.vue";

const root_element = document.getElementById("chat") as HTMLElement;

const nonce = root_element.getAttribute("nonce");

const webchaturl = root_element.dataset["webchaturl"];

buildClient(`${webchaturl}/chatbot/v1.0`);

const config = {
  ...root_element.dataset,
  stylenonce: nonce,
};

const launchApp = () => {
  const app = createApp({
    render: () => h(App, config as any),
  });
  app.mount("#chat");
};

launchApp();
