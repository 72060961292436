import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chatTextWrapper" }
const _hoisted_2 = { class: "chatTextCloseWrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["webchatPopup", { firstLoad: _ctx.firstLoad, hideText: _ctx.hideText }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("button", {
        class: _normalizeClass(["chatText", { initialLoad: _ctx.isFirstPage }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showChat')))
      }, _toDisplayString(_ctx.introText), 3), [
        [_vShow, _ctx.isShow]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("button", {
          "aria-label": "Hide chatbot text",
          class: _normalizeClass(["chatTextClose", { initialLoad: _ctx.isFirstPage }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleHideChatBotText($event)))
        }, [
          _createElementVNode("img", {
            src: _ctx.imagePath + '/close-icon.svg',
            alt: "",
            role: "presentation"
          }, null, 8, _hoisted_3)
        ], 2), [
          [_vShow, _ctx.isShow]
        ])
      ])
    ]),
    _createElementVNode("button", {
      "aria-label": "Open chatbot",
      class: _normalizeClass(["chatbotOpen", { initialLoad: _ctx.isFirstPage }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('showChat')))
    }, [
      _createElementVNode("img", {
        id: "chatbot-open",
        src: _ctx.imagePath + '/chatbot-bubble.svg',
        alt: "",
        role: "presentation"
      }, null, 8, _hoisted_4)
    ], 2)
  ], 2))
}