import { chatbot } from "./client";
import { GetDirectlineTokenResponse } from "./webchat/src/models";

export const getToken = async (): Promise<
  GetDirectlineTokenResponse | undefined
> => {
  try {
    const response = await chatbot.getDirectlineToken();
    return response;
  } catch (e) {
    console.log("gettoken: An exception occurred:", e);
    return undefined;
  }
};
