<template>
  <div class="webchat-banner-wrapper showWebchat">
    <div class="webchat-banner-inner">
      <div class="bannerLogo">
        <img
          :src="imagePath + '/calmacChatbotLogo.svg'"
          alt=""
          role="presentation"
        />
        <div class="bannerLogoText">
          <h5>Mac</h5>
          <p>Your helpful support bot</p>
        </div>
      </div>
      <div class="bannerIcons">
        <img
          class="shrinkIcon"
          :src="imagePath + '/shrinkIconWebChat.svg'"
          alt=""
          role="presentation"
          @click="$emit('shrink')"
        />
        <img
          class="closeIcon"
          :src="imagePath + '/closeIconWebChat.svg'"
          alt=""
          role="presentation"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
  props: {
    imagePath: {
      type: String,
      required: true,
    },
  },
});
</script>
