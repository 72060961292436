import { getToken } from "@/api/getToken";
import { reconnect } from "@/api/reconnect";
import { IWebChatResponse } from "@/types";
import {
  getConversationDetailsFromStorage,
  saveConversationDetailsToStorage,
} from "./storage";

export const determineToken = async (): Promise<
  IWebChatResponse | undefined
> => {
  const details: IWebChatResponse | undefined =
    getConversationDetailsFromStorage();

  if (details?.conversationId) {
    // Attempt to reconnect using these credentials
    const reconnectResponse = await reconnect({
      token: details?.token,
      conversationId: details?.conversationId,
    });

    if (reconnectResponse) {
      saveConversationDetailsToStorage(reconnectResponse);
      return reconnectResponse;
    }
  }

  const response = await getToken();
  if (response) {
    saveConversationDetailsToStorage(response);
  }
  return response;
};
