import * as coreClient from "@azure/core-client";

export const ConversationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ConversationsResponse",
    modelProperties: {
      conversationId: {
        serializedName: "conversationId",
        type: {
          name: "String",
        },
      },
      token: {
        serializedName: "token",
        type: {
          name: "String",
        },
      },
      streamUrl: {
        serializedName: "streamUrl",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DirectLineTokenDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DirectLineTokenDetails",
    modelProperties: {
      token: {
        serializedName: "token",
        required: true,
        type: {
          name: "String",
        },
      },
      expiresIn: {
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0,
        },
        serializedName: "expiresIn",
        required: true,
        type: {
          name: "Number",
        },
      },
      conversationId: {
        serializedName: "conversationId",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};
