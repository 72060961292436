<template>
  <div>
    <div
      v-show="!connectionError && !minimized"
      class="webchat-container showWebchat"
      ref="botwindow"
    ></div>
    <h1 v-if="connectionError">There has been a connection error</h1>
  </div>
</template>

<script lang="ts">
import { buildStore } from "../store";
import { getStyleSet, buildStyleOptions } from "../constants";
import { defineComponent } from "@vue/runtime-core";
import { determineToken } from "@/util";

export default defineComponent({
  props: {
    botAvatarInitials: {
      type: String,
      required: true,
    },
    userAvatarInitials: {
      type: String,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
    stylenonce: {
      type: String,
      required: true,
    },
    topic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      connectionError: false,
    };
  },
  async mounted() {
    const credentials = await determineToken();
    if (!credentials?.token) {
      this.connectionError = true;
      return;
    }

    const directLine = window.createDirectLine({
      token: credentials.token,
      webSockets: true,
      watermark: 10,
    });

    const botWindow = this.$refs.botwindow as HTMLElement;
    if (botWindow) {
      window.WebChat.renderWebChat(
        {
          directLine: directLine,
          store: buildStore(this.topic),
          styleSet: getStyleSet(),
          styleOptions: buildStyleOptions({
            botAvatarInitials: this.botAvatarInitials,
            userAvatarInitials: this.userAvatarInitials,
          }),
          nonce: this.stylenonce,
        },
        botWindow,
      );
    }
  },
});
</script>
