import { chatbot } from "./client";
import { ConversationsResponse } from "./webchat/src/models";

export const reconnect = async ({
  token,
  conversationId,
}: {
  token?: string;
  conversationId: string;
}): Promise<ConversationsResponse | undefined> => {
  try {
    const response = await chatbot.conversations(conversationId, {
      requestOptions: {
        customHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
    return response;
  } catch (error: any) {
    console.log("reconnect: An error occurred:", error);
    return undefined;
  }
};
