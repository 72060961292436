import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  ChatbotOptionalParams,
  ConversationsOptionalParams,
  ConversationsOperationResponse,
  GetDirectlineTokenOptionalParams,
  GetDirectlineTokenResponse,
  GetMessagesOptionalParams,
  PostMessagesOptionalParams,
} from "./models";

export class Chatbot extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the Chatbot class.
   * @param options The parameter options
   */
  constructor(options?: ChatbotOptionalParams) {
    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: ChatbotOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-chatbot/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint:
        options.endpoint ??
        options.baseUri ??
        "https://dev-drinkaware-api.azure-api.net/chatbot/v1",
    };
    super(optionsWithDefaults);

    // Assigning values to Constant parameters
    this.$host =
      options.$host || "https://dev-drinkaware-api.azure-api.net/chatbot/v1";
  }

  /**
   * Conversations
   * @param conversationId
   * @param options The options parameters.
   */
  conversations(
    conversationId: string,
    options?: ConversationsOptionalParams,
  ): Promise<ConversationsOperationResponse> {
    return this.sendOperationRequest(
      { conversationId, options },
      conversationsOperationSpec,
    );
  }

  /**
   * Generate and get a Directline token object
   * @param options The options parameters.
   */
  getDirectlineToken(
    options?: GetDirectlineTokenOptionalParams,
  ): Promise<GetDirectlineTokenResponse> {
    return this.sendOperationRequest(
      { options },
      getDirectlineTokenOperationSpec,
    );
  }

  /**
   * GET end point for requests messages
   * @param options The options parameters.
   */
  getMessages(options?: GetMessagesOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, getMessagesOperationSpec);
  }

  /**
   * POST end point for requests messages
   * @param options The options parameters.
   */
  postMessages(options?: PostMessagesOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, postMessagesOperationSpec);
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const conversationsOperationSpec: coreClient.OperationSpec = {
  path: "/conversations/{conversationId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ConversationsResponse,
    },
    400: {},
    500: {},
    503: {},
  },
  queryParameters: [Parameters.watermark],
  urlParameters: [Parameters.$host, Parameters.conversationId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getDirectlineTokenOperationSpec: coreClient.OperationSpec = {
  path: "/tokens/generate",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DirectLineTokenDetails,
    },
    400: {},
    500: {},
    503: {},
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getMessagesOperationSpec: coreClient.OperationSpec = {
  path: "/messages",
  httpMethod: "GET",
  responses: { 200: {}, 400: {}, 500: {}, 503: {} },
  urlParameters: [Parameters.$host],
  serializer,
};
const postMessagesOperationSpec: coreClient.OperationSpec = {
  path: "/messages",
  httpMethod: "POST",
  responses: { 200: {}, 400: {}, 500: {}, 503: {} },
  urlParameters: [Parameters.$host],
  serializer,
};
