export const getStyleSet = () =>
  window.WebChat.createStyleSet({
    rootHeight: "100%",
    rootWidth: "100%",
  });

export const buildStyleOptions = ({
  botAvatarInitials,
  userAvatarInitials,
}: {
  botAvatarInitials: string;
  userAvatarInitials: string;
}) => {
  return {
    botAvatarInitials,
    userAvatarInitials,
    suggestedActionLayout: "stacked", // either "carousel" or "stacked"
  };
};
