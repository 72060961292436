import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "webchatMainWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StartWebChat = _resolveComponent("StartWebChat")!
  const _component_WebChatBanner = _resolveComponent("WebChatBanner")!
  const _component_WebChat = _resolveComponent("WebChat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_StartWebChat, {
      firstLoad: _ctx.firstLoad,
      imagePath: _ctx.imagepath,
      introText: _ctx.introtext,
      onShowChat: _ctx.handleShowChat
    }, null, 8, ["firstLoad", "imagePath", "introText", "onShowChat"]), [
      [_vShow, _ctx.showStartChat]
    ]),
    (_ctx.showBanner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_WebChatBanner, {
            imagePath: _ctx.imagepath,
            onClose: _ctx.handleClose,
            onShrink: _ctx.handleShrink
          }, null, 8, ["imagePath", "onClose", "onShrink"]),
          (_ctx.showWebchat)
            ? (_openBlock(), _createBlock(_component_WebChat, {
                key: 0,
                botAvatarInitials: _ctx.botavatarinitials,
                userAvatarInitials: _ctx.useravatarinitials,
                minimized: _ctx.minimized,
                stylenonce: _ctx.stylenonce,
                topic: _ctx.topic
              }, null, 8, ["botAvatarInitials", "userAvatarInitials", "minimized", "stylenonce", "topic"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}