import { IWebChatResponse } from "@/types";

const WEBCHAT_DETAILS = "webchat_details";

export const getConversationDetailsFromStorage = ():
  | IWebChatResponse
  | undefined => {
  try {
    const webchatDetailsStr = window.localStorage.getItem(WEBCHAT_DETAILS);
    const webChatDetails = webchatDetailsStr
      ? JSON.parse(webchatDetailsStr)
      : undefined;

    return webChatDetails;
  } catch (e) {
    console.log(
      "An error ocurred trying to retrieve the conversation details:",
      e,
    );

    return undefined;
  }
};

export const saveConversationDetailsToStorage = (details: IWebChatResponse) => {
  if (!details) {
    return;
  }

  try {
    const webChatDetailsStr = JSON.stringify(details);
    window.localStorage.setItem(WEBCHAT_DETAILS, webChatDetailsStr);
  } catch (e) {
    console.log(
      "An error ocurred trying to store the conversation details:",
      e,
    );
  }
};

export const clearConversation = () => {
  window.localStorage.removeItem(WEBCHAT_DETAILS);
};
